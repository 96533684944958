<template>
  <slot />
</template>

<script setup lang="ts">
import { type GUID, useCommon } from '@arora/common'

const { getParameters } = useUrl()
const { stringIsNullOrWhitespace } = useCommon()

const router = useRouter()

const clientStore = useClientStore()
const popupStore = usePopupStore()

const { isLoggedIn } = storeToRefs(useAccountStore())
const appConfig = useAppConfig()
const { eventEmit } = useEmitter()
const { repeatOrder } = useAccount()
const hasPromo = computed<boolean>(() => {
  return !stringIsNullOrWhitespace(clientStore.ClientState?.data?.Promo?.PromoCode)
})

async function updateGetParameters(): Promise<void> {
  if (getParameters.value.has('showLoginPopup') && !isLoggedIn.value) {
    await popupStore.openPopup({
      onClosePopup: () => {
        eventEmit('v-reset-captcha')
      },
      popupClosable: appConfig.VueSettingsPreRun.LoginShowCloseButton,
      popupName: 'accountManagementPopup'
    })
  }

  if (getParameters.value.has('promocode')) {
    const promoInfo = await clientStore.checkPromoCode(
      getParameters.value.get('promocode')!
    )
    if (promoInfo && promoInfo.Active && !promoInfo.Use && !hasPromo.value)
      await popupStore.openPopup({
        popupClosable: true,
        popupName: 'promoInUrlPopup',
        popupProperties: new Map<string, unknown>([
          ['code', getParameters.value.get('promocode')],
          ['subtitle', promoInfo.PopupSubtitle],
          ['title', promoInfo.PopupTitle]
        ])
      })
  }
  if (getParameters.value.has('orderRepeatId')) {
    await repeatOrder(getParameters.value.get('orderRepeatId') as GUID)
  }
}

onMounted(() => {
  updateGetParameters()
})

const removeRouterCheck = router.afterEach(() => updateGetParameters())

onUnmounted(() => {
  if (removeRouterCheck) removeRouterCheck()
})
</script>
